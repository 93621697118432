function sendNotifyMe(_this){
    var me = _this;
    var form = $(me).parent('.notify-box-button');
    var l = Ladda.create(me);

    l.start();

    var form = $(me).parents('.notify-me-form-1');
    var email = $(form).find('input[name=email]').val() || null;
    var access_beta = $(form).find('input[name=access_beta]').prop('checked') || false;
    var type = $(form).find('input[name=type]').val();
    var object = $(form).find('input[name=object]').val();
    var subscribe_page = window.location.href;
    var name = $(form).find('input[name=name]').val() || null;

    $.ajax({
        url: '/api/v1/auth/notify-me',
        type: 'POST',
        dataType: "json",
        data: {'email': email, 'subscribe_page': subscribe_page, 'object': object, 'type':type, access_beta: access_beta, 'name':name},
        success: function (data) {
            switch (data.status) {
                case 'success':
                    $('.desktop .c-popup').fadeOut();
                    $('.tablet .c-popup, .mobile .c-popup').hide();

                    if(!$('body').hasClass('page-projects')){
                        $(form).removeClass('active');
                    }

                    show_alert(data.message, 'success');
                    $(form).find('input').val('');
                    break;
                case 'error':
                    // console.log(data);

                    if(!$(form).find('input[name=name]').hasClass('success')){
                        $(form).find('input[name=name]').addClass('error');
                    }
                    if(!$(form).find('input[name=email]').hasClass('success') ){
                        $(form).find('input[name=email]').addClass('error');
                    }

                    show_alert(data.message, 'error');


//                    if(data.code == '1000'){
//                        $(me).parent().find('.email-input, .subscribe').removeClass('success').addClass('error');
//                    }
                    break;
                case 'exists':

                    if(!$('body').hasClass('page-projects')){
                        $(form).removeClass('active');
                    }

                    $('.desktop .c-popup').fadeOut();
                    $('.tablet .c-popup, .mobile .c-popup').hide();
                    show_alert(data.message, 'info');
                    break;
                default:
                    show_alert('error', 'error');
            }
            l.stop();
        }
    });
}

$(document).on('click', '.notify-me, .notify-me-button', function (e) {
    e.preventDefault();
    var form = $(this).closest('.notify-box-button');
    var object = $(form).find('input[name=object]').val() || null;
    var type = $(form).find('input[name=type]').val() || null;
    if(!userIsLogined && !$(form).hasClass('active')){
        // if(!userIsLogined && form.length>0 && !$(form).hasClass('active')){
        // var object = $(form).find('input[name=object]').val();                
        $(form).addClass('active');
        // return;
    }
    MA_ClickNotifyMe(e, object, type);

    sendNotifyMe(this);
});