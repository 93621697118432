$(document).ready(function(){
    var pushNotifyClass = '.sp_notify_prompt';
    var pushIntervalAfterStart = 10000;
    $(window).bind('startPushSubscribe user.signUp', function(event, params){
        setTimeout(function(){
            $(pushNotifyClass).click();
            // console.log('PushSubscribe event with interval ' + pushIntervalAfterStart);
        },pushIntervalAfterStart);
    });

    // when the page is loaded
    var pathname = window.location.pathname;
    var regex = /^\/(((store|news)\/.{1,})|(about|news|download-app|faq-and-support))$/g;
    var isShowOnPage = regex.exec(pathname);

    if(isShowOnPage){
        $(window).trigger('startPushSubscribe');
    }

});


