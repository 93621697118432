// onclick=""
// ?_ym_debug=1
function MA_JoinClick(event, usertype){
    if(usertype === 'b2b'){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'b2b', 'button', 'register');
    }else{
        if (typeof window['ga'] == 'function') ga('send', 'event', 'b2с', 'button', 'join');
    }        
}
function MA_LoginClick(event, usertype){
    if(usertype === 'b2b'){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'b2b', 'button', 'login');
    }else{
        if (typeof window['ga'] == 'function') ga('send', 'event', 'b2с', 'button', 'login');
    }        
}
function MA_LoginFacebookEvent(event, isB2b, isNew){
    usertype = isB2b ? 'b2b' : 'b2c';
    if (typeof window['ga'] == 'function'){
        if(isNew){
            ga('send', 'event', usertype, 'button', 'join_facebook');
        }else{
            ga('send', 'event', usertype, 'button', 'login_facebook');
        }    
    }
}
function MA_LoginGoogleEvent(element, isB2b, isNew){
    usertype = isB2b ? 'b2b' : 'b2c';
    if (typeof window['ga'] == 'function'){
        if(isNew){
            ga('send', 'event', usertype, 'button', 'join_google');
        }else{
            ga('send', 'event', usertype, 'button', 'login_google');
        }    
    }
}

function MA_JoinSuccess(event, usertype){
    if (typeof window['ga'] == 'function') ga('send', 'event', usertype, 'enter', 'register_success');     
}
function MA_LoginSuccess(event, usertype){
    if (typeof window['ga'] == 'function') ga('send', 'event', usertype, 'enter', 'login_success');    
}
// function MA_JoinSuccessFBPixel(event, user_type){
//     if (typeof window['fbq'] == 'function') {
//         fbq('track', 'CompleteRegistration', {
//             user_type:user_type
//         });               
//     }
// }

function initiateSubscription(subscription_type) {
    if (typeof window['fbq'] == 'function') {
        fbq('trackCustom', 'InitiateSubscription', {
            subscription_type: subscription_type,
            user_type: 'b2c'
        });               
    }
}

function chooseRole(user_role_group, user_role) {
    if (typeof window['fbq'] == 'function') {
        fbq('trackCustom', 'ChooseRole', {            
            user_type: 'b2b',
            user_role_group: user_role_group,
            user_role: user_role
        });               
    }
}

function initiateRegistration(user_type) {
    if (typeof window['fbq'] == 'function') {
        fbq('trackCustom', 'InitiateRegistration', {user_type: user_type});
    } else {
        setTimeout(function () {
            if (typeof window['fbq'] == 'function') {
                fbq('trackCustom', 'InitiateRegistration', {user_type: user_type});
            }
        }, 1000);
    }
}
    

function MA_DownloadAppLink(href, appPlatform){ 
    if (typeof window['ga'] == 'function') {
        switch(appPlatform) {
            case 'google':
                ga('send', 'event', 'button', 'download_app', 'android');
                break;
            case 'amazon':
                ga('send', 'event', 'button', 'download_app', 'amazon');
                break;
            case 'appstore':
                ga('send', 'event', 'button', 'download_app', 'ios');
                break;
            default:
                break;
        }
    }
}

function MA_ClickNotifyMe(event, object, type){
    object = object || null;
    if (typeof window['ga'] == 'function') {
        if (type === 'notify me book pages') {
            ga('set', 'dimension7', object);
            ga('send', 'event', 'button', 'notify_me', object); 
        } else {
            ga('send', 'event', 'button', 'subscribe', object);           
        }             
    }
}

function MA_GoPayPal(content_ids, content_name, content_category, paymentMethod, value, currency, subscription_type){
    if (typeof window['ga'] == 'function') ga('send', 'event', 'button', 'payment_method', paymentMethod);  
    if (typeof window['fbq'] == 'function') {
        fbq('trackCustom', 'InitiatePayment', {
            content_ids: content_ids,
            content_name: content_name,
            content_category: content_category,
            content_type: 'product',
            payment_method: paymentMethod,
            subscription_type: subscription_type,
            value: value,
            currency: currency
        });
    }
}

function MA_ClickBuy(event, type, book){
    if(type == 'pre_order'){
        var gaEvent = 'pre_order';
    }else{
        var gaEvent = 'order';
    }    
    if (typeof window['ga'] == 'function') {
        ga('set', 'dimension7', book); 
        ga('send', 'event', 'button', gaEvent, book);
    }
           
}
function MA_GoHome(link){
    if (typeof window['ga'] == 'function') handleOutboundLinkClicks(link, 'click'); 
}
$(document).ready(function(){
    //example $(window).trigger('myEvent2', {bb:'sss'});
    $(window).bind('profile.sendConfirm profile.sendChangeEmailConfirm', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'profile', 'resend_confirm_email');
    });
    $(window).bind('store.enterCode', function(event, params){
        if(params.type == 'redemption'){
            if (typeof window['ga'] == 'function') ga('send', 'event', params.page, 'Redemption_code');
        } 
        if(params.type == 'promo'){
            if (typeof window['ga'] == 'function') ga('send', 'event', params.page, 'Promo_code');
        }
    });
    $(window).bind('profile.connected.success', function(event, params){        
        var params  = params || {provider:null}
        var prvoder = params.provider;
        switch(prvoder){
            case 'facebook': 
                var gaEvent = 'switch_soc_facebook';
                break;
            case 'google': 
                var gaEvent = 'switch_soc_google'; 
                break;
            default:
                // console.log('Error provider!');
                return false;
        }
        if (typeof window['ga'] == 'function') ga('send', 'event', 'profile', gaEvent);
    });
    $(window).bind('user.comment', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'send', 'comment');
    });
    $(window).bind('user.review', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'send', 'review');
    });

    $(window).bind('cart.add', function(event, content_ids,content_name,content_category,value, currency){
        if (typeof window['fbq'] == 'function') {
            fbq('track', 'AddToCart', {
                content_ids: content_ids,
                content_name: content_name,
                content_category: content_category,
                content_type: 'product',
                value: value,
                currency: currency
            });               
        }
    });
    $(window).bind('cart.step3', function(event, content_ids,content_name,content_category,value, currency){
        if (typeof window['fbq'] == 'function') {
            fbq('track', 'AddPaymentInfo', {
                content_ids: content_ids,
                content_name: content_name,
                content_category: content_category,
                value: value,
                content_type: 'product',
                currency: currency
            }); 
        }
    });
    $(window).bind('subscribe.step.method', function(event, content_ids,content_name,content_category, subscription_type,value, currency){
        if (typeof window['fbq'] == 'function') {
            fbq('track', 'AddPaymentInfo', {
                content_ids: content_ids,
                content_name: content_name,
                content_category: content_category,
                content_type: 'product',
                subscription_type: subscription_type,
                value: value,
                currency: currency
            }); 
        }
    });
    $('.analitic-download').click(function(e){
        var size = $(this).data('size');
        var data = size?{'size':size}:{};
        var gaEvent = $(this).data('ga');
        data.page = document.location.href;
        if (typeof window['ga'] == 'function') ga('send', 'event', 'download', gaEvent); 
    });  

    $(window).bind('tailor.buyNowHeader', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'buy_now_header', 'click');
    });
    
    $(window).bind('tailor.andrAppPopup', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'download_app_popup', 'andr');
    });
    
    $(window).bind('tailor.iosAppPopup', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'download_app_popup', 'ios');
    });
    
    $(window).bind('tailor.amazAppPopup', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'download_app_popup', 'amaz');
    });
    
    $(window).bind('landing.andrApp', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'download_app', 'andr');
    });
    
    $(window).bind('landing.iosApp', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'download_app', 'ios');
    });
    
    $(window).bind('landing.amazApp', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'download_app', 'amaz');
    });
    
    $(window).bind('tailor.videoObzClose', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'Videos_obz', 'close');
    });
    
    $(window).bind('tailor.videoObzPlay', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'Videos_obz', 'play');
    });
    
    $(window).bind('landing.notify', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'email', 'enter');
    });
    
    $(window).bind('gift.audioClick', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'click_audio_block2', 'lend_27_12');
    });
    
    $(window).bind('hypnos.audioClick', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'click_audio_block2', 'lend_hipnos');
    });
    
    $(window).bind('tailor.buyNowPopup', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'buy_now_popup', 'click');
    });
    
    $(window).bind('tailor.buyNow', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'buy_now_cart', 'click');
    });
    
    $(window).bind('magi.hypnos.videoClose', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'Videos', 'close');
    });
    
  
    $(window).bind('magi.hypnos.buyNow', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'buy_now', 'click');
    });
    
    $(window).bind('magi.hypnos.buyBanner', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'banner', 'button');
    });
    
    $(window).bind('magi.hypnos.moreBanner', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'banner', 'button_LEARN_MORE');
    });
    
    $(window).bind('checkout.tailor.downloadApp', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'Download-App-cart', 'click');
    });
    
    $(window).bind('checkout.tailor.enterPromo', function(event, params){
        if (typeof window['ga'] == 'function') ga('send', 'event', 'Promo-code-cart', 'enter');
    });
    
    
    
});
//$(window).trigger('checkout.tailor.enterPromo');